
.tabnav {
    border-radius: 0 !important;
    border: none !important;
    transition: all 0.3s ease-in-out;
  }
  
  .tabnav:hover {
    border:0 !important;
  }
  
  .nav-link.active.tabnav {
    background-color: #fff !important; /* Active tab background color */
    color: #00a0e3 !important; /* Active tab text color */
    border: none;
    border-radius: 0 !important;
    border-bottom: 2px solid #00a0e3 !important; /* Active tab border color */
  }
  
  .nav-item {
    margin-right: 5px;
  }
  