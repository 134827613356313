.covisit-profile img{
    width:100%;
    height:100%;
  }
  .covisit-card {
    transition: all 0.3s ease;
    overflow: hidden;
  }
  
  .covisit-card img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
  
  .covisit-card.active-card {
    height: auto; /* Adjust based on your requirements */
    width: 100%;
  }
  
  .covisit-card.inactive-card {
    height: 150px; /* Default height */
    width: 100%;
  }
  
  .covisit-card-body {
    padding: 1rem; /* Adjust as needed */
  }
  
  .covisit-card.active-card .card-body {
    padding: 2rem; /* Adjust as needed */
  }
.visitor-card {
    width: 100%; /* Ensure uniform width */
    height: 250px; /* Set a fixed height for default state */
    overflow: hidden; /* Prevent overflow */
  }
  
  .visitor-card.expanded {
    height: auto; /* Allow height to increase when expanded */
  }
  
  .visitor-image {
    width: 100px; /* Set a fixed width for the image */
    height: 100px; /* Set a fixed height for the image */
    object-fit: cover; /* Maintain aspect ratio */
  }

  .gatepass-card {
    flex: 0 0 20%;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    transition: height 0.3s ease;
  }
  .visitor-card{
    flex: 0 0 30% ;
    height: auto;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    transition: height 0.3s ease;
  }
  