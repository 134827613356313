@import "../../abstracts/variable";
@import "../../abstracts/mixin";
[data-sidebar-style="full"][data-layout="vertical"] {
  .header {
    width: calc(100% - 15rem);
    left: 15rem;
  }
  .deznav {
    .metismenu {
      & > li {
        & > a {
          font-size: 1rem;
          padding: 0.75rem 1.5rem;
          margin: 0px;
          @include transitionMedium;
        }
        &.mm-active {
          & > a {
            i {
              color: $white;
              font-weight: 300;
            }
          }
        }
        .has-arrow:after {
          right: 1.5rem;
        }
      }
    }
  }
  .menu-toggle {
    .nav-header {
      width: 3.75rem;
      z-index: 999;
      .brand-logo {
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
        .color-title {
          display: none;
        }
      }
      .nav-control {
        right: -3rem;
        .hamburger {
          .line {
            background-color: var(--primary);
          }
        }
      }
    }
    .nav-header-bar {
      width: 3.75rem;
    }
    .copyright,
    .plus-box,
    .help-desk {
      display: none;
    }
    .header {
      padding-left: 7.5rem;
      width: calc(100% - 3.75rem);
      left: 3.74rem;
      @at-root [direction="rtl"]#{&} {
        padding: 0 0.9375rem;
        padding-right: 7.5rem;
      }
      .header-content {
        padding-left: 4.1rem;
      }
    }
    .deznav {
      width: 3.75rem;
      overflow: visible;
      position: absolute;
      .nav-text {
        display: none;
      }
      .slimScrollDiv,
      .deznav-scroll {
        overflow: visible !important;
      }
      .header-info2 {
        padding: 0;
      }
      .sidebar-info {
        display: none !important;
      }
      .header-profile2 {
        margin: 0 0.5rem;
      }
      .metismenu {
        li {
          position: relative;

          a {
            background: transparent;
            margin: 0.125rem 0;

            svg {
              max-width: 1.5rem;
              max-height: 1.5rem;
              margin-right: 0;
            }
            &:before {
              content: none;
            }
            i {
              margin: 0;
            }
          }
          & > ul {
            position: absolute;
            left: 3.7rem;
            top: 0;
            width: 12rem;
            z-index: 1001;
            display: none;
            padding-left: 0.0625rem;
            height: auto !important;
            box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
            margin-left: 0;
            border: 0;
            border-radius: 0.375rem;
            background: $white;

            @at-root [direction="rtl"]#{&} {
              left: auto;
              right: 5rem;
              // box-shadow: -0.375rem 0.375rem 0.625rem rgba(0, 0, 0, 0.15);
            }
            li:hover {
              ul {
                // display: block;
                left: 11.8125rem;
                top: 0;
                &:after {
                  content: none;
                }
              }
            }
          }
          &:hover > ul {
            display: block;
            height: auto;
            overflow: visible;
          }
        }
        & > li {
          transition: all 0.4s ease-in-out;
          //padding: 0.125rem 1.25rem;

          & > a {
            padding: 0.725rem 0.775rem;
            margin: 3px 0px;
            text-align: center;

            &.has-arrow {
              &:after {
                display: none;
              }
            }
          }
          &.mm-active > a {
            background-color: rgba(13, 153, 255, 0.05);

            i {
              color: $white;
              padding: 0;
            }
          }
          &:hover {
            &:nth-last-child(-n + 1) {
              & > ul {
                bottom: 0;
                top: auto;
              }
            }
            & > a {
              background-color: rgba(13, 153, 255, 0.05);
              color: $white;
              @at-root [data-theme-version="dark"]#{&} {
                background: $dark-card;
              }
              i {
                color: $white;
              }
            }
            & > ul {
              height: auto !important;
              padding: 0.625rem 0;

              a {
                padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                margin-left: -0.1rem;
              }
              ul {
                padding: 0.625rem 0;
                a {
                  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                  margin-left: -0.1rem;
                }
              }
            }
          }
        }
        .nav-label,
        .nav-badge {
          display: none;
        }
        .menu-title {
          display: none;
        }
      }
      .user-profile {
        width: 3.3rem;
        left: 0.2rem;
        .user-profile-card {
          .user-profile-item {
            .user-profile-icon {
              .avatar-text {
                font-size: 16px;
                width: 2rem;
                height: 2rem;
                line-height: 2rem;
              }
            }
            .user-profile-text {
              display: none;
            }
          }
        }
      }
    }

    .content-body {
      margin-left: 3.7rem;
      @at-root [direction="rtl"]#{&} {
        margin-right: 5.7rem;
        margin-left: auto;
        border: 0;
      }
    }
    & + .footer {
      padding-left: 5.7rem;
      @at-root [direction="rtl"]#{&} {
        padding-left: 0;
        padding-right: 5.7rem;
      }
    }
  }
}
[data-sidebar-style="full"][data-layout="horizontal"] {
  .header .header-content {
    padding-left: 1.875rem;
  }
}
[data-nav-headerbg="color_1"] {
  .menu-toggle {
    .color-title {
      display: none !important;
    }
  }
}
.show-more-btn {
  padding: 0.75rem 1.5rem;
  font-size: 0.813rem !important;
  color: var(--primary) !important;
  &:before {
    display: none;
  }
  &:active {
    border: 0 !important;
  }

  &.collapsed {
    border: 0;
  }
}
